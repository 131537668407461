import * as React from 'react';
// components
import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  List,
  ListItem,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
// styles
import * as styles from './skills.module.scss';
// image
import websiteImage from '../images/website-building.svg';
// data
import skills from '../constants/skills';

const Skills = () => {
  return (
    <section id="skills" className={styles.wrapper}>
      <Box className={styles.skewed}></Box>
      <Container maxW="container.xl">
        <Box>
          <Heading
            className={styles.title}
            as="h2"
            size="3xl"
            color="black"
            textAlign="center"
            fontSize={{ base: '36px', md: '44px', lg: '52px' }}
          >
            <span className={styles.highlighted}>Compétences</span>
          </Heading>
        </Box>

        <SimpleGrid className={styles.grid}>
          <Box className={styles.pic}>
            <Image
              src={websiteImage}
              alt="Website building"
              htmlWidth="400px"
              htmlHeight="154px"
              objectFit="contain"
            />
          </Box>

          {skills.map(skill => {
            return (
              <Box key={skill.id} className={styles[`${skill.className}`]}>
                <Box marginBottom="8px">{skill.icons}</Box>
                <Heading className={styles.skillTitle} as="h4" size="lg">
                  {skill.name}
                </Heading>
                <Text fontSize="md" color="gray.500">
                  {skill.description}
                </Text>
              </Box>
            );
          })}
        </SimpleGrid>
      </Container>
    </section>
  );
};

export default Skills;
