import * as React from 'react';
// components
import { Box, Heading, Link, Tag, Text } from '@chakra-ui/react';
// styles
import * as styles from './timeline.module.scss';
// icons
// data
import diplomas from '../constants/diplomas';
const Timeline = () => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.timeline}>
        {diplomas.map(diploma => {
          return (
            <Box key={diploma.id} className={styles.timelineContainer}>
              <Text
                className={styles.timelineYear}
                fontSize="2xl"
                color="gray.700"
              >
                {diploma.year}
              </Text>
              <Box className={styles.timelineBody}>
                <Tag
                  size="md"
                  backgroundColor="#38B2AC"
                  color="#FFFFFF"
                  borderRadius="full"
                  margin="0 0 8px 0"
                >
                  {diploma.category}
                </Tag>
                <Heading size="lg" margin="0 0 8px 0">
                  {diploma.name}
                </Heading>
                <Text fontSize="md" color="gray.500" margin="0 0 16px 0">
                  {diploma.description}
                </Text>
                <Link className="links" src={diploma.institutionUrl}>
                  {diploma.institution}
                </Link>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Timeline;
