import React from 'react';
// components
import { Icon } from '@chakra-ui/react';
//icons
import { FaGraduationCap } from 'react-icons/fa';
import { FcDiploma1, FcDiploma2 } from 'react-icons/fc';

interface diploma {
  id: number;
  name: string;
  category: string;
  description: string;
  institution: string;
  institutionUrl: string;
  year: number;
  icon: JSX.Element;
}

const diplomas: Array<diploma> = [
  {
    id: 0,
    name: 'Baccalauréat ès sciences (B. Sc.)',
    category: 'Universitaire',
    description:
      'Composé des certificats en informatique appliquée, analyse d’affaires TI et cybersécurité, mes études post-secondaires ont bonifiées mes compétences en informatique au delà de la programmation.',
    institution: 'Université de Montréal',
    institutionUrl: 'https://www.umontreal.ca/',
    year: 2021,
    icon: <Icon as={FaGraduationCap}></Icon>,
  },
  {
    id: 1,
    name: 'Technique en informatique de gestion',
    category: 'Collégiale',
    description:
      'C\'est dans le cadre de ce programme que j\'ai appris la base de l\'informatique, notamment en ce qui attrait à la programmation orienté objet et aux patrons de conception utilisé en développement logiciel.',
    institution: 'Cégep du Vieux-Montréal',
    institutionUrl: 'https://www.cvm.qc.ca/',
    year: 2020,
    icon: <Icon as={FcDiploma1}></Icon>,
  },
  {
    id: 2,
    name: "Diplômes d'études collégiales en sciences humaines",
    category: 'Collégiale',
    description:
      'Formation pré-universitaire, la plupart de mes cours de concentration furent en géographie et en anthropologie.',
    institution: 'Cégep de Sainte-Foy',
    institutionUrl: 'https://www.cegep-ste-foy.qc.ca/',
    year: 2014,
    icon: <Icon as={FcDiploma1}></Icon>,
  },
  {
    id: 3,
    name: "Diplôme d'études secondaires",
    category: 'Secondaire',
    description:
      'Formation générale.',
    institution: 'École secondaire de Neufchâtel',
    institutionUrl:
      'https://www.cscapitale.qc.ca/ecole/ecole-secondaire-de-neufchatel/',
    year: 2012,
    icon: <Icon as={FcDiploma2}></Icon>,
  },
];

export default diplomas;
