import * as React from 'react';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
import Topnav from '../components/topnav';
import Intro from '../components/intro';
import Skills from '../components/skills';
import Projects from '../components/projects';
import Education from '../components/education';
import Experience from '../components/experience';
import Contact from '../components/contact';
// styles
import '../styles/index.scss';

const Index = () => (
  <Layout>
    <SEO title="Portefolio" lang="fr" />
    <Topnav />
    <Intro />
    <Skills />
    <Projects />
    <Education />
    <Experience />
    <Contact />
  </Layout>
);

export default Index;
