import React, { useEffect, useState } from 'react';
// components
import {
  Box,
  Container,
  Flex,
  Heading,
  List,
  ListItem,
  Tag,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
// styles
import * as styles from './experience.module.scss';
// datas
import jobs from '../constants/jobs';

const Experience = () => {
  const [tabOrientation, setTabOrientation] = useState<
    'vertical' | 'horizontal'
  >('vertical');
  const [justifyContent, setJustifyContent] = useState<string>('flex-start');
  let isLargeViewport = true;

  React.useEffect(() => {
    const adjustTabs = () => {
      const width = window.innerWidth;
      isLargeViewport = width > 992 ? true : false;
      if (!isLargeViewport) {
        setTabOrientation('horizontal');
        setJustifyContent('center');
        return;
      }
      setTabOrientation('vertical');
      setJustifyContent('flex-start');
    };

    adjustTabs();
    window.addEventListener('resize', () => {
      adjustTabs();
    });
  }, []);

  return (
    <section id="experience" className={styles.wrapper}>
      <Box className={styles.skewed}></Box>
      <Box>
        <Container className={styles.container} maxW="container.xl">
          <Heading
            className={styles.title}
            as="h2"
            size="3xl"
            color="black"
            textAlign="center"
            fontSize={{ base: '40px', md: '52px', lg: '60px' }}
          >
            <span className={styles.highlighted}>Expérience</span>
          </Heading>
          <Flex justifyContent="center">
            <Tabs
              className={styles.tabs}
              orientation={tabOrientation}
              colorScheme="teal"
            >
              <TabList
                className={styles.tabList}
                justifyContent={justifyContent}
              >
                {jobs.map(job => {
                  return (
                    <Tab key={job.id}>
                      {job.startDate.getFullYear() +
                        '-' +
                        job.endDate.getFullYear()}
                    </Tab>
                  );
                })}
              </TabList>

              <TabPanels className={styles.tabPanels}>
                {jobs.map(job => {
                  return (
                    <TabPanel
                      key={job.id}
                      className={styles.tabPanel}
                      borderWidth="1px"
                      borderRadius="lg"
                      bg="rgba(255,255,255,0.8)"
                      boxShadow="1px 1px #d5d9de"
                    >
                      <Heading
                        fontSize={{ base: '16px', md: '17px', lg: '18px' }}
                        marginBottom="8px"
                      >
                        {job.company}
                      </Heading>
                      <Heading
                        fontSize={{ base: '20px', md: '22px', lg: '24px' }}
                        marginBottom="16px"
                        whiteSpace="pre-line"
                      >
                        {job.name}
                      </Heading>

                      <List>
                        {job.tasks.map((task, index) => {
                          return (
                            <ListItem
                              key={index}
                              color="gray.500"
                              listStyleType="circle"
                              margin="0 0 8px 16px"
                            >
                              {task}
                            </ListItem>
                          );
                        })}
                      </List>
                      <Tag
                        size="md"
                        backgroundColor="#38B2AC"
                        color="#FFFFFF"
                        borderRadius="full"
                        margin="16px 0 0 0"
                      >
                        {job.category}
                      </Tag>
                    </TabPanel>
                  );
                })}
              </TabPanels>
            </Tabs>
          </Flex>
        </Container>
      </Box>
    </section>
  );
};

export default Experience;
