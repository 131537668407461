import React from 'react';
// components
import { Icon } from '@chakra-ui/react';
// icons
import {
  DiNodejsSmall,
  DiHtml5,
  DiSass,
  DiReact,
  DiMysql,
  DiMsqlServer,
  DiMongodb,
  DiNpm,
} from 'react-icons/di';

import {
  SiJavascript,
  SiTypescript,
  SiGatsby,
  SiNextDotJs,
  SiCloudflare,
  SiLinux,
  SiNginx,
  SiDigitalocean,
  SiWebpack,
  SiVisualstudiocode,
  SiGit,
} from 'react-icons/si';

interface skill {
  id: number;
  className: string;
  name: string;
  description: string;
  icons: Array<JSX.Element>;
}

const skills: Array<skill> = [
  {
    id: 0,
    className: 'js',
    name: 'Javascript',
    description:
      'De loin mon langage de programmation préféré, javascript/typescript est au coeur de la plupart de mes projets.',
    icons: [
      <Icon key="0" as={SiJavascript} color="yellow.300" />,
      <Icon key="1" as={SiTypescript} color="blue.400" />,
      <Icon key="2" as={DiNodejsSmall} color="green.500" />,
      <Icon key="3" as={DiNpm} color="red.500" />,
    ],
  },
  {
    id: 1,
    className: 'react',
    name: 'React',
    description:
      'Que ce soit avec Create-React-App, Next.js ou Gatsby, React est mon outil de prédilection pour la majorité des applications web.',
    icons: [
      <Icon key="0" as={DiReact} color="cyan.300" />,
      <Icon key="1" as={SiNextDotJs} color="black" />,
      <Icon key="2" as={SiGatsby} color="purple.600" />,
    ],
  },
  {
    id: 2,
    className: 'html',
    name: 'Html & Sass',
    description:
      'Un incontournable pour tout site web, je maîtrise HTML et SASS (CSS) depuis maintenant plusieurs années.',
    icons: [
      <Icon key="0" as={DiHtml5} color="orange.500" />,
      <Icon key="1" as={DiSass} color="pink.500" />,
    ],
  },

  {
    id: 3,
    className: 'db',
    name: 'Base de données',
    description:
      "MySQL et MS SQL sont les bases de données auxquelles je suis le plus famillier. J'utilise également MongoDB pour certains projets.",
    icons: [
      <Icon key="0" as={DiMysql} color="blue.600" />,
      <Icon key="1" as={DiMsqlServer} color="gray.600" />,
      <Icon key="2" as={DiMongodb} color="green.500" />,
    ],
  },
  {
    id: 4,
    className: 'dep',
    name: 'Déploiement',
    description:
      'De la Gestion du DNS via Cloudflare à la configuration de serveurs Linux avec Nginx, je déploie manuellement mes projets sur DigitalOcean.',
    icons: [
      <Icon key="0" as={SiCloudflare} color="orange.400" />,
      <Icon key="1" as={SiLinux} color="gray.700" />,
      <Icon key="2" as={SiNginx} color="green.500" />,
      <Icon key="4" as={SiDigitalocean} color="blue.500" />,
    ],
  },
  {
    id: 5,
    className: 'tool',
    name: 'Autre outils',
    description:
      "Visual Studio Code est mon éditeur de choix et comme tout développeur j'utilise GIT pour le version control. Connaissance de Webpack.",
    icons: [
      <Icon key="0" as={SiVisualstudiocode} color="blue.600" />,
      <Icon key="1" as={SiGit} color="orange.500" />,
      <Icon key="2" as={SiWebpack} color="blue.300" />,
    ],
  },
];

export default skills;
