import * as React from 'react';

// components
import { Link } from 'gatsby';
import {
  Box,
  IconButton,
  Container,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  List,
  ListItem,
  useDisclosure,
} from '@chakra-ui/react';
// styles
import * as styles from './topnav.module.scss';
// constants
import links from '../constants/links';
// icons
import { HamburgerIcon } from '@chakra-ui/icons';

const Topnav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef<HTMLButtonElement>(null);

  return (
    // tablet & desktop
    <header className={styles.header}>
      <nav>
        <Container className={styles.container} maxW="container.xl">
          <Box className={styles.logo}>
            <Link to="/">Romej</Link>
          </Box>
          <List className={styles.menu}>
            {links.map(link => (
              <ListItem className={styles.navList} key={link.id}>
                <Link className={styles.navLink} to={link.url}>
                  {link.name}
                </Link>
              </ListItem>
            ))}
            ;
            <IconButton
              aria-label="Open menu"
              className={styles.menuButton}
              ref={btnRef}
              icon={<HamburgerIcon />}
              colorScheme="teal"
              onClick={onOpen}
            ></IconButton>
          </List>
        </Container>
      </nav>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        trapFocus={false}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody className={styles.drawer}>
              {links.map(link => (
                <ListItem key={link.id} className={styles.navList}>
                  <Link
                    className={styles.navLink}
                    to={link.url}
                    onClick={onClose}
                  >
                    {link.name}
                  </Link>
                </ListItem>
              ))}
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </header>
  );
};

export default Topnav;
