import React from 'react';
// components
import { Image } from '@chakra-ui/react';
//images
import entremise from '../images/entremise-thumbnail-min.jpg';
import cdh from '../images/cdh-thumbnail.jpg';
import tkLogin from '../images/login.png';
import ogTkLogin from '../images/og/login.png';
import ogTkCreateTask from '../images/og/create-task.png';
import ogTkEditTask from '../images/og/edit-task.png';
import ogTkTasks from '../images/og/tasks.png';
import ogTkRegister from '../images/og/register.png';
import FireEmblemTactics from '../images/fire-emblem-tactics.png';
import ogFireEmblemTactics from '../images/og/fire-emblem-tactics.png';
import pokebase from '../images/pokebase.png';
import ogPokebase from '../images/og/pokebase.png';
import snakeGame from '../images/snake-game.png';
import ogSnakeGame from '../images/og/snake-game.png';
import biomeSimulator from '../images/biome-simulator.png';
import ogBiomeSimulator from '../images/og/biome-simulator.png';

interface project {
  id: number;
  title: string;
  type: string;
  thumbnail: JSX.Element;
  images: Array<JSX.Element>;
  description: string;
  stack: Array<string>;
  url: string;
  isAvailable: boolean;
}

const projects: Array<project> = [
  {
    id: 1,
    title: 'Entremise',
    type: 'Site web',
    thumbnail: <Image src={entremise} />,
    images: [<Image src={entremise} />],
    description:
      "Intégration du site web d'Entremise, une OBNL oeuvrant dans l'occupation transitoire.",
    stack: ['html', 'css', 'javascript'],
    url: 'https://entremise.ca',
    isAvailable: true,
  },
  // {
  //   id: 2,
  //   title: 'Cité des Hospitalières',
  //   type: 'Site web',
  //   image: <Image src={cdh} />,
  //   description:
  //     'Développement du site web de la Cité des hospitalières en partenariat avec Entremise.',
  //   stack: ['html', 'css', 'javascript', 'wordpress'],
  //   url: 'https://cdhentransition.ca',
  //   isAvailable: true,
  // },
  {
    id: 3,
    title: 'Fire Emblem Tactics',
    type: 'Jeu',
    thumbnail: <Image src={FireEmblemTactics} />,
    images: [<Image src={ogFireEmblemTactics} />],
    description: 'Jeu de cartes avec pour thème la série Fire Emblem.',
    stack: ['html', 'sass', 'javascript', 'php'],
    url: 'https://romej.ca/fire-emblem-tactics',
    isAvailable: false,
  },
  {
    id: 4,
    title: 'Pokebase',
    type: 'Site web',
    thumbnail: <Image src={pokebase} />,
    images: [<Image src={ogPokebase} />],
    description: 'Base de données indexant 802 pokémons.',
    stack: ['html', 'sass', 'javascript', 'nodejs'],
    url: 'https://romej.ca/pokebase',
    isAvailable: false,
  },
  {
    id: 5,
    title: 'Task Keeper',
    type: 'App',
    thumbnail: <Image src={tkLogin} />,
    images: [
      <Image src={ogTkLogin} />,
      <Image src={ogTkRegister} />,
      <Image src={ogTkTasks} />,
      <Image src={ogTkCreateTask} />,
      <Image src={ogTkEditTask} />,
    ],
    description: 'Une liste de tâches pour Android.',
    stack: ['android', 'java'],
    url: '',
    isAvailable: false,
  },
  {
    id: 6,
    title: 'Snake',
    type: 'Jeu',
    thumbnail: <Image src={snakeGame} />,
    images: [<Image src={ogSnakeGame} />],
    description: 'Reproduction du classic snake game.',
    stack: ['c++'],
    url: '',
    isAvailable: false,
  },
  {
    id: 7,
    title: 'Biome Simulator',
    type: 'Simulateur',
    thumbnail: <Image src={biomeSimulator} />,
    images: [<Image src={ogBiomeSimulator} />],
    description: "Simulation graphique d'un écosystème.",
    stack: ['python'],
    url: '',
    isAvailable: false,
  },
];

export default projects;
