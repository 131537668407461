import * as React from 'react';
// components
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
// styles
import * as styles from './intro.module.scss';
// icons
import { Icon } from '@chakra-ui/react';
import { VscGithub } from 'react-icons/vsc';

const Intro = () => {
  const goToProjects = () => {
    window.location.hash = '#projects';
  };
  const goToGithubProfile = () => {
    window.open('https://github.com/Romej');
  };
  return (
    <section className={styles.wrapper}>
      <Box className={styles.skewed}></Box>
      <Box color="white">
        <Container className={styles.container} maxW="container.xl">
          <Flex className={styles.contentWrapper} flexDirection="column">
            <Heading
              className={styles.title}
              as="h1"
              fontSize={{ base: '40px', md: '52px', lg: '60px' }}
            >
              Jérôme Claveau
            </Heading>
            <Heading
              className={styles.subtitle}
              as="h3"
              fontSize={{ base: '26px', md: '32px', lg: '36px' }}
            >
              Développeur Web
            </Heading>
            <Text
              className={styles.bio}
              fontSize={{ base: '16px', md: '20px', lg: '22px' }}
            >
              Je suis un diplômé à la recherche d'une opportunité de carrière.
            </Text>
            <Box>
              <Button
                colorScheme="teal"
                variant="solid"
                marginRight="16px"
                onClick={goToProjects}
              >
                Voir mes projets
              </Button>
              <Button
                rightIcon={<VscGithub />}
                colorScheme="teal"
                variant="outline"
                onClick={goToGithubProfile}
              >
                Profil Github
              </Button>
            </Box>
          </Flex>
        </Container>
      </Box>
    </section>
  );
};

export default Intro;
