// extracted by mini-css-extract-plugin
export const wrapper = "skills-module--wrapper--1L2JH";
export const title = "skills-module--title--17Id7";
export const highlighted = "skills-module--highlighted--3rJAd";
export const skewed = "skills-module--skewed--29j3T";
export const grid = "skills-module--grid--2_74L";
export const pic = "skills-module--pic--3Jmyx";
export const js = "skills-module--js--TH1_h";
export const html = "skills-module--html--1mfAI";
export const react = "skills-module--react--1NL_K";
export const db = "skills-module--db--qP5sX";
export const dep = "skills-module--dep--3SMHu";
export const tool = "skills-module--tool--14RMu";
export const skillTitle = "skills-module--skillTitle--3Ng8u";