import React, { useState } from 'react';
// components
import {
  Box,
  Container,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
// styles
import * as styles from './projects.module.scss';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { IoMdImages } from 'react-icons/io';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, EffectFade, Navigation, Pagination } from 'swiper';
// install Swiper modules
SwiperCore.use([A11y, EffectFade, Navigation, Pagination]);
// data
import projects from '../constants/projectsData';

const Projects = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent]: [JSX.Element[], any] = useState([]);
  const modalSlides = () => {
    return (
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation
        effect="fade"
        lazy={true}
      >
        {modalContent.map((image: JSX.Element, index: number) => {
          return (
            <SwiperSlide key={index} className={styles.slide}>
              {image}
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  };

  return (
    <section id="projects" className={styles.wrapper}>
      <Container maxW="container.xl">
        <Box>
          <Heading
            className={styles.title}
            as="h2"
            color="black"
            textAlign="center"
            fontSize={{ base: '40px', md: '52px', lg: '60px' }}
          >
            <span className={styles.highlighted}>Projets</span>
          </Heading>
        </Box>

        <SimpleGrid
          className={styles.grid}
          columns={{ base: 1, md: 2, xl: 3 }}
          spacing={10}
        >
          {projects.map(project => {
            return (
              <Box
                key={project.id}
                width="100%"
                maxW="sm"
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                bg="rgba(255,255,255,0.8)"
                boxShadow="1px 1px #d5d9de"
                margin="0 auto"
              >
                <Box position="relative" width="100%">
                  {project.thumbnail}
                </Box>
                <VStack
                  padding="16px 24px 16px 24px"
                  h="256px"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Box width="100%" position="relative">
                    <Flex>
                      <Heading size="lg" margin="0 0 16px 0">
                        {project.title}
                      </Heading>
                      {project.isAvailable ? (
                        <IconButton
                          aria-label="Visit"
                          rounded="3xl"
                          colorScheme="teal"
                          marginLeft="auto"
                          onClick={() => window.open(project.url)}
                          icon={<ExternalLinkIcon />}
                        />
                      ) : (
                        <IconButton
                          className={styles.smHidden}
                          aria-label="Fullscreen"
                          rounded="3xl"
                          colorScheme="teal"
                          marginLeft="auto"
                          onClick={() => {
                            setModalContent(project.images);
                            onOpen();
                          }}
                          icon={<IoMdImages />}
                        />
                      )}
                    </Flex>

                    <Text
                      fontSize={{ base: '16px' }}
                      color="gray.500"
                      marginBottom="8px"
                    >
                      {project.description}
                    </Text>
                  </Box>
                  <VStack alignItems="flex-start">
                    <Stack direction="row">
                      {project.stack.map((technology, index) => {
                        return (
                          <Tag key={index} size="md" borderRadius="full">
                            {technology.toUpperCase()}
                          </Tag>
                        );
                      })}
                    </Stack>
                  </VStack>
                </VStack>
              </Box>
            );
          })}
        </SimpleGrid>
      </Container>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent padding="24px">
          <ModalCloseButton />
          <ModalBody>{modalSlides()}</ModalBody>
        </ModalContent>
      </Modal>
    </section>
  );
};

export default Projects;
