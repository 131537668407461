interface link {
  id: number;
  name: string;
  url: string;
}

const data: Array<link> = [
  {
    id: 0,
    name: 'Compétences',
    url: '#skills',
  },
  {
    id: 1,
    name: 'Projets',
    url: '#projects',
  },
  {
    id: 3,
    name: 'Éducation',
    url: '#education',
  },
  {
    id: 4,
    name: 'Expérience',
    url: '#experience',
  },
  {
    id: 5,
    name: 'Contact',
    url: '#contact',
  },
];

export default data;
