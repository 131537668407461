import { Icon, Link } from '@chakra-ui/react';
import React from 'react';
// icons
import { MdEmail, MdPhoneAndroid, MdLocationOn } from 'react-icons/md';

interface ContactInfo {
  id: number;
  title: string;
  value: JSX.Element;
  icon: JSX.Element;
}

const contactInfos: Array<ContactInfo> = [
  {
    id: 0,
    title: 'Adresse courriel',
    value: (
      <Link href="mailto:claveau.je@gmail.com" fontSize="lg">
        claveau.je@gmail.com
      </Link>
    ),
    icon: <Icon as={MdEmail} color="pink.200" boxSize={8}></Icon>,
  },
  {
    id: 1,
    title: 'Téléphone',
    value: (
      <Link href="tel:581-984-4271" fontSize="lg">
        581-984-4271
      </Link>
    ),
    icon: <Icon as={MdPhoneAndroid} color="pink.200" boxSize={8}></Icon>,
  },
  {
    id: 2,
    title: 'Ville',
    value: (
      <Link
        href="https://goo.gl/maps/Bm58MGzZVmf25qMv5"
        target="_blank"
        rel="noopener"
        fontSize="lg"
      >
        Québec, QC
      </Link>
    ),
    icon: <Icon as={MdLocationOn} color="pink.200" boxSize={8}></Icon>,
  },
];

export default contactInfos;
