import * as React from 'react';
// components
import {
  Center,
  Circle,
  Container,
  Heading,
  Icon,
  Image,
  Link,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
// styles
import * as styles from './contact.module.scss';
// data
import contactInfos from '../constants/contactInfos';
// images
import worldMap from '../images/world-map.svg';
import { FaRegTimesCircle } from 'react-icons/fa';

const Contact = () => {
  return (
    <section id="contact" className={styles.wrapper}>
      <Container maxW="container.xl">
        <Heading
          className={styles.title}
          as="h2"
          size="3xl"
          color="black"
          textAlign="center"
          fontSize={{ base: '40px', md: '52px', lg: '60px' }}
        >
          <span className={styles.highlighted}>Contact</span>
        </Heading>
        <SimpleGrid columns={[1, 3]} color="#FFFFFF" marginBottom="24px">
          {contactInfos.map(contactInfo => {
            return (
              <Center
                key={contactInfo.id}
                flexDirection="column"
                marginBottom="64px"
              >
                <Circle size="64px" bg="pink.800" marginBottom="24px">
                  {contactInfo.icon}
                </Circle>
                <Heading size="xl" marginBottom="16px">
                  {contactInfo.title}
                </Heading>
                {contactInfo.value}
              </Center>
            );
          })}
        </SimpleGrid>
        <Center>
          <Image src={worldMap} htmlWidth="1248px" htmlHeight="485px"></Image>
        </Center>
      </Container>
    </section>
  );
};

export default Contact;
