import * as React from 'react';
// components
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import Timeline from './timeline';
// styles
import * as styles from './education.module.scss';
// icons

const Education = () => {
  return (
    <section id="education" className={styles.wrapper}>
      <Box color="white">
        <Container maxW="container.xl">
          <Heading
            className={styles.title}
            as="h2"
            size="3xl"
            color="black"
            textAlign="center"
            fontSize={{ base: "40px", md: "52px", lg: "60px"}}
          >
            <span className={styles.highlighted}>Éducation</span>
          </Heading>
          <Timeline />
        </Container>
      </Box>
    </section>
  );
};

export default Education;
