import React from 'react';

interface job {
  id: number;
  name: string;
  description: string;
  tasks: Array<string>;
  company: string;
  category: string;
  startDate: Date;
  endDate: Date;
}

const jobs: Array<job> = [
  {
    id: 0,
    name: 'Responsable des systèmes informatiques \n et du développement web',
    description: '',
    tasks: [
      'Développer des sites webs utilisant un gestionnaire de contenu (Strapi, Wordpress, Webflow).',
      'Développer des applications facilitant la communication avec le grand public.',
      'Émettre et appliquer des recommandations TI en entreprise.',
    ],
    company: 'Entremise',
    category: 'Consultant',
    startDate: new Date(2020, 2, 1),
    endDate: new Date(2021, 0, 1),
  },
  {
    id: 1,
    name: 'Agent de soutien en TI et en programmation',
    description:
      'Accroître le partage des expertises, des connaissances et des pratiques numériques dans le milieu culturel, en vue d’assurer collectivement une meilleure connaissance du développement culturel numérique.',
    company: 'Entremise',
    tasks: [
      'Création et mise en ligne de formulaires pour recevoir les projets de la communauté.',
      'Soutien technique et entretient des systèmes informatiques.',
      "Recherche et mise en place de nouveaux logiciels.",
    ],
    category: 'Stage',
    startDate: new Date(2019, 5, 1),
    endDate: new Date(2020, 2, 1),
  },
  {
    id: 2,
    name: 'Agent au service à la clientèle',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    tasks: [
      "Offrir du support technique aux clients pour l’utilisation de sites web et d'applications mobiles.",
      'Inscription téléphonique de nouveaux abonnés à la plateforme de gestion.',
      'Communiquer avec les éditeurs par courriel.',
    ],
    company: 'Inovva',
    category: 'Emploi étudiant',
    startDate: new Date(2016, 10, 1),
    endDate: new Date(2019, 5, 1),
  },
];

export default jobs;
